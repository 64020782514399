import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./page404style.scss";

import img from "../../assets/img/questionMark.jpg";

export const ErrorMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(6);

  useEffect(() => {
    const countdownTimer = setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
    if (countdown === 0) {
      navigate("/");
    }
    return () => clearTimeout(countdownTimer);
  }, [countdown, navigate]);

  return (
    <div className="error">
      <p className="error__message error__message--big">
        {t("page_not_found")}
      </p>
      <img className="error__img" src={img} alt="error-img" />
      <p className="error__message">
        {t("return_in")} {countdown} {t("seconds")}
      </p>
      <button className="error__btn" onClick={() => navigate("/")}>
        {t("return")}
      </button>
    </div>
  );
};
