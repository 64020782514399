import { useState, useEffect } from "react";

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // 768px - стандарт для мобільних

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Оновлюємо стан при зміні розміру
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); // Видаляємо слухача, коли компонент видаляється
    };
  }, []);

  return isMobile;
};

export const isMobile = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
    userAgent
  );
};
