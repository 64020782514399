import { useState, useEffect } from "react";
import axios from "axios";

export const useHttp = (setVideos) => {
  const [videosList, setVideosList] = useState([]);

  useEffect(() => {
    const fetchPlaylist = async () => {
      // Пошук даних у sessionStorage перед запитом на сервер
      const storedVideos = sessionStorage.getItem("playList");
      if (storedVideos) {
        const parsedVideos = JSON.parse(storedVideos);
        setVideosList(parsedVideos);
        setVideos(parsedVideos); // Передаємо список відео у батьківський компонент
        return; // Якщо дані знайдено у sessionStorage, не робимо запит на сервер
      }

      // Якщо даних немає у sessionStorage, робимо запит на сервер
      const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
      const PLAYLIST_ID = process.env.REACT_APP_YOUTUBE_PLAYLIST_1;

      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/playlistItems`,
          {
            params: {
              part: "snippet",
              maxResults: 50,
              playlistId: PLAYLIST_ID,
              key: API_KEY,
            },
          }
        );
        const fetchedVideos = response.data.items;

        // Зберігаємо отримані дані у sessionStorage
        sessionStorage.setItem("playList", JSON.stringify(fetchedVideos));

        setVideosList(fetchedVideos);
        setVideos(fetchedVideos); // Передаємо список відео у батьківський компонент
      } catch (error) {
        console.error("Error fetching playlist:", error);
      }
    };

    fetchPlaylist();
  }, [setVideos]);

  return videosList;
};
