import { useState, useEffect } from "react";
import { isMobile } from "./isMobile";

export const BlockScroll = () => {
  if (isMobile()) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "hidden";
    document.body.style.borderRight = "7px solid rgba(0, 0, 0, 0.7)";
    document.querySelector(".scrollBtn").style.right = `27px`;
    document.querySelector(".headers").style.right = `7px`;
  }
};

export const UnblockScroll = () => {
  if (isMobile()) {
    document.body.style.overflow = "";
  } else {
    document.body.style.overflow = "";
    document.body.style.borderRight = "";
    document.querySelector(".scrollBtn").style.right = `20px`;
    document.querySelector(".headers").style.right = `0px`;
  }
};

// Хук useScrollPosition видає true, якщо:
// Ширина вікна менша або дорівнює 600 пікселям, або
// Ширина вікна більше 600 пікселів і сторінка прокручена більше ніж на 150 пікселів.
// Він поверне false, якщо:
// Ширина вікна більше 600 пікселів і сторінка не прокручена на більше ніж 150 пікселів.
export const useScrollPosition = () => {
  const [isWidthLessThan600, setIsWidthLessThan600] = useState(
    window.innerWidth <= 600
  );
  const [isScrollBelow500, setIsScrollBelow500] = useState(
    window.scrollY <= 150
  );

  useEffect(() => {
    const checkWindowWidth = () => {
      setIsWidthLessThan600(window.innerWidth <= 600);
    };
    const checkScrollPosition = () => {
      setIsScrollBelow500(
        window.innerWidth > 600 ? window.scrollY > 150 : false
      );
    };
    window.addEventListener("resize", checkWindowWidth);
    window.addEventListener("scroll", checkScrollPosition);

    // Перевірка при монтажі, щоб визначити початковий стан
    checkWindowWidth();
    checkScrollPosition();
    return () => {
      window.removeEventListener("resize", checkWindowWidth);
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);
  return isWidthLessThan600 || isScrollBelow500;
};

// Хук useScrollHeight видає:
// true – якщо прокручування сторінки перевищує заданий поріг (за замовчуванням 300 пікселів або кастомне значення, якщо передано).
// false – якщо прокручування сторінки не досягло вказаного порогу.
export const useScrollHeight = (threshold = 300) => {
  const [isHeightScrolled, setIsHeightScrolled] = useState(
    window.scrollY > threshold
  );

  useEffect(() => {
    const handleScroll = () => {
      setIsHeightScrolled(window.scrollY > threshold);
    };

    window.addEventListener("scroll", handleScroll);

    // Перевіряємо початкове значення при монтуванні
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }; // Очистка події при демонтажі
  }, [threshold]); // Залежність від змінного порогу

  return isHeightScrolled;
};

export function scrollToElement(index) {
  const playlistContainer = document.querySelector(".playlist__ul"); // Знаходимо контейнер списку
  const scrollPoint = playlistContainer.querySelectorAll("li")[index]; // Знаходимо елемент за індексом
  const offset = 120;

  if (scrollPoint && playlistContainer) {
    const scrollPosition =
      scrollPoint.offsetTop - playlistContainer.offsetTop - offset;

    playlistContainer.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
    scrollPoint.classList.add("open");
  } else {
    console.error(
      `Element with index ${index} not found or playlist container not found`
    );
  }
}
