import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SwitchBtn } from "../SwitchBtn/switchBtn";
import { useScrollPosition, useScrollHeight } from "../../utils/scroll";
import { useIsMobile } from "../../utils/isMobile";
import { ParticleCanvas } from "./animate/ParticlesCanvas";
import { Radio } from "../radio/radio";

import avaImg from "../../assets/img-logo/kovadlo1.png";
import "./headerStyle.scss";

// Імпортуємо всі GIF-картинки з папки music
const importAll = (r) => r.keys().map(r);
const gifImages = importAll(
  require.context("../../assets/gif-music", false, /\.(gif)$/)
);

export const Header = ({ mod }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const headerResize = useScrollPosition();
  const isHeightScrolled = useScrollHeight(isMobile ? 250 : 600);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackName, setTrackName] = useState({});
  const [showTrack, setShowTrack] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [currentGifIndex, setCurrentGifIndex] = useState(0); // Для ротатора GIF

  const { t } = useTranslation();

  // Оновлюємо стан isHeaderHidden залежно від прокручування
  useEffect(() => {
    if (isHeightScrolled) {
      setIsHeaderHidden(true);
    } else {
      setIsHeaderHidden(false);
    }
  }, [isHeightScrolled]);

  useEffect(() => {
    if (isPlaying && trackName) {
      setShowTrack(true); // Показуємо трек

      // Оновлюємо GIF
      setCurrentGifIndex((prevIndex) => (prevIndex + 1) % gifImages.length);

      // Анімація для треку
      const trackElement = document.querySelector(".track");
      if (trackElement) {
        trackElement.classList.remove("track--animated");

        const animationTimeout = setTimeout(() => {
          trackElement.classList.add("track--animated");
        }, 50);

        // Приховуємо трек через 6 секунд
        const hideTrackTimeout = setTimeout(() => {
          setShowTrack(false);
        }, 6000);

        return () => {
          clearTimeout(animationTimeout);
          clearTimeout(hideTrackTimeout);
        };
      }
    }
  }, [trackName, isPlaying]);

  const getParticleCount = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 500) {
      return 4;
    } else if (screenWidth <= 900) {
      return 7;
    } else if (screenWidth <= 1200) {
      return 12;
    } else if (screenWidth <= 1500) {
      return 15;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleLogoClick = () => {
    navigate("/");
    scrollToTop();
  };

  return (
    <main className="headers">
      {isMobile || (
        <div
          className={`header ${
            headerResize ? "header--short" : "header--normal"
          } ${isHeaderHidden ? "header--hiden" : "header--hiden-exit"}`}
        >
          <img
            className="header__logo"
            onClick={handleLogoClick}
            src={avaImg}
            alt="MBO-logo"
          />

          <ParticleCanvas
            headerResize={headerResize}
            particleCount={getParticleCount()}
          />
        </div>
      )}

      <button
        className={`scrollBtn ${headerResize ? "visible" : "hidden"}`}
        onClick={scrollToTop}
      >
        <span className={`scrollBtn__arrow`}></span>
      </button>

      <div className="header__subHeader">
        <Radio
          setTrackName={setTrackName}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
        <SwitchBtn headerResize={headerResize} />

        {isPlaying && (
          <div
            className={`track ${
              showTrack ? "show track--animated" : "track--hiding"
            }`}
          >
            <img
              className="track__gif"
              src={gifImages[currentGifIndex]} // Відображаємо поточну GIF-картинку
              alt="muzGif"
            />
            <div className="track__text">
              <p className="track__artist">{trackName.artistName}</p>
              <div className="track__divider"></div>
              <p className="track__song">{trackName.songName}</p>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};
