import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./sliderStyle.scss";

import MatrixGif from "../../../assets/gif/matrix.gif";
import MatrixImg from "../../../assets/img/matrixImg.jpg";

export const Slider = () => {
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    // Імітуємо завантаження
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Затримка для імітації завантаження
    return () => clearTimeout(timer);
  }, []);

  // Перемикаємо слайди з інтервалом
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrent((prev) => (prev === 0 ? 0 : prev + 1));
    }, 7000);
    return () => clearInterval(intervalId); // Очищаємо інтервал під час розмонтовування компоненту
  }, []);

  return (
    <section className="slider">
      <div
        className={
          current === 0
            ? "slider__slide slider__slide--active"
            : "slider__slide"
        }
      >
        <img
          className="slider__image"
          src={loading ? MatrixImg : MatrixGif}
          alt={loading ? "Matrix Image" : "Matrix GIF"}
        />
      </div>
    </section>
  );
};

// import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
// import "./sliderStyle.scss";
// import MatrixGif from "../../../assets/gif/matrix.gif";

// // Імпортуємо слайди
// const images = require.context(
//   "../../../assets/slides/",
//   false,
//   /\.(png|jpe?g|svg)$/
// );

// export const Slider = () => {
//   const [current, setCurrent] = useState(0);
//   const [imageUrls, setImageUrls] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const { t } = useTranslation();

//   useEffect(() => {
//     // Імпортуємо всі зображення один раз після монтування компонента
//     const importedImages = images.keys().map((image) => images(image));
//     setImageUrls(importedImages);
//     setLoading(false);
//   }, []);

//   // Перемикаємо слайди з інтервалом
//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       setCurrent((prev) => (prev === imageUrls.length - 1 ? 0 : prev + 1));
//     }, 7000);
//     return () => clearInterval(intervalId); // Очищаємо інтервал під час розмонтовування компоненту
//   }, [imageUrls.length]);

//   // Навігація
//   const moveDot = (index) => {
//     setCurrent(index);
//   };

//   if (loading) {
//     return (
//       <article className="slider">
//         <span className="slider__spinner"></span>
//       </article>
//     );
//   }

//   return (
//     <section className="slider">
//       {/* <article className="slider__title">
//         <p className="slider__title-text">{t("website_development")}</p>
//         <p className="slider__title-text2">{t("turnkey")}</p>
//         <button className="slider__title-btn" alt="logo">
//           {t("order")}
//         </button>
//       </article> */}

//       <div className="dots">
//         {imageUrls.map((_, index) => (
//           <div
//             key={index}
//             onClick={() => moveDot(index)}
//             className={
//               current === index ? "dots__dot dots__dot--active" : "dots__dot"
//             }
//           ></div>
//         ))}
//       </div>

//       {imageUrls.map((url, idx) => (
//         <div
//           key={idx}
//           className={
//             idx === current
//               ? "slider__slide slider__slide--active"
//               : "slider__slide"
//           }
//         >
//           <img
//             className="slider__image"
//             id={idx}
//             src={url}
//             alt={`Зображення ${idx + 1}`}
//           />
//         </div>
//       ))}
//     </section>
//   );
// };
