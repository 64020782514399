import React, { createContext } from "react";
import ReactDOM from "react-dom/client";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import App from "./App";
// import dotenv from "dotenv";
// dotenv.config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig); // Initialize Firebase
// const auth = getAuth(app);
// const firestore = getFirestore(app);
// const storage = getStorage(app);
// const functions = getFunctions(app);
export const Context = createContext(null);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Context.Provider
    value={
      {
        // auth,
        // firestore,
        // storage,
        // functions,
      }
    }
  >
    <App />
  </Context.Provider>
);
