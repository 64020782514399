import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setCookie, getCookie } from "../../utils/cookies";
import { isMobile } from "../../utils/isMobile";
import "../../utils/i18n";

// Імпортування зображень прапорців
import enFlag from "../../assets/img/icon-en.png";
import uaFlag from "../../assets/img/icon-ua.png";
import uaFlag1 from "../../assets/img/flagIcon-ua.png";
import enFlag1 from "../../assets/img/flagIcon-uk.png";
import "./swithbtnStyle.scss";

const flags = [
  { lang: "en", img: enFlag, img1: enFlag1, label: "English" },
  { lang: "ua", img: uaFlag, img1: uaFlag1, label: "Українська" },
];

export function SwitchBtn() {
  const { i18n } = useTranslation();
  const [currentFlag, setCurrentFlag] = useState(0);
  const [showLangMenu, setShowLangMenu] = useState(false);

  // Отримання збереженої мови з кука або стандартної (en)
  useEffect(() => {
    const savedLang = getCookie("lang") || "en";
    const foundIndex = flags.findIndex((flag) => flag.lang === savedLang);
    if (foundIndex !== -1) {
      setCurrentFlag(foundIndex);
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  const changeLanguage = (index) => {
    setCurrentFlag(index);
    const nextLang = flags[index].lang;
    i18n.changeLanguage(nextLang);
    setCookie("lang", nextLang, 7);
    setShowLangMenu(false);
  };

  return (
    <main
      className={`switch ${showLangMenu ? "switch--expand" : ""}`}
      onMouseEnter={!isMobile ? () => setShowLangMenu(true) : undefined}
      onMouseLeave={() => setShowLangMenu(false)}
      onClick={() => setShowLangMenu(true)}
    >
      {showLangMenu ? (
        <>
          {flags.map((flag, index) => (
            <div
              key={index}
              className={`switch__menu-flag ${
                index === currentFlag ? "switch__btn2" : ""
              }`}
              onClick={(event) => {
                event.stopPropagation();
                changeLanguage(index);
                setShowLangMenu(false);
              }}
            >
              <div className="switch__row">
                <img
                  className="switch__flag-img1"
                  src={flag.img1}
                  alt="flagImg"
                />
                <p>{flag.label}</p>
              </div>
            </div>
          ))}
        </>
      ) : (
        <img
          className="switch__flag-img"
          src={flags[currentFlag].img}
          alt="Current flag"
        />
      )}
    </main>
  );
}
