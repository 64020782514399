import React, { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Header } from "./components/header/header";
import { Slider } from "./pages/mainPage/slider/slider";
import { TabsGrid } from "./pages/mainPage/tabsGrid/tabsGrid";
import { Footer } from "./components/footer/footer";
import { ErrorMessage } from "./pages/page404/page404";
import Spinner from "./components/spinner";
import "./style/styles.scss";
import "./style/scroller.scss";

export default function App() {
  return (
    <Suspense
      fallback={
        <main
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            backgroundColor: "black",
          }}
        >
          <Spinner />
        </main>
      }
    >
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <main className="app">
                <Header mod={"main"} />
                <Slider />
                <TabsGrid />
                <Footer />
              </main>
            }
          />

          <Route path="*" element={<ErrorMessage />} />
        </Routes>

        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Router>
    </Suspense>
  );
}
